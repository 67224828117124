import { useUserData } from '@conteg/auth';
import {
  Box,
  Button,
  ButtonWithConfirmation,
  Checkbox,
  ErrorDetailInfo,
  Flex,
  Loading,
  showAlertModalError,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { CUSTOM_AUTH_TOKEN } from 'config';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ServiceType,
  StorageUnitDocument,
  UpdateContentStateEnum,
  useStorageUnitQuery,
  useUpdateContentStateMutation,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

const EndCurrentReservation = ({
  storageUnitId,
}: {
  storageUnitId: string;
}) => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const userData = useUserData(CUSTOM_AUTH_TOKEN);
  const userId = userData?.userId;
  const [isPickedUpBySender, setIsPickedUpBySender] = useState<boolean>(false);

  const {
    data: { tenantSubjectId, trackingIdentifier, contentId, serviceType } = {},
    isLoading,
    error: storageUnitError,
  } = useStorageUnitQuery(
    {
      pointId,
      storageUnitId,
    },
    {
      select: (data) => ({
        trackingIdentifier:
          data.storageUnit.allocation?.content?.trackingIdentifiers?.[0]
            ?.identifier,
        tenantSubjectId:
          data.storageUnit.allocation?.reservation?.tenantSubjectId,
        contentId: data.storageUnit.allocation?.content?.id,
        serviceType: data.storageUnit.allocation?.reservation?.serviceType,
      }),
    }
  );

  const invalidate = useInvalidateQueries();

  const { mutate: updateReservationState } = useUpdateContentStateMutation();

  const [endReservationResult, setEndReservationResult] = useState<string>(
    trackingIdentifier === null
      ? 'Page.StorageUnit.Otp.EndReservation.ReservationError'
      : ''
  );
  const [displayEndButton, setDisplayEndButton] = useState<boolean>(
    trackingIdentifier !== null
  );

  const handleEndReservation = () => {
    updateReservationState(
      {
        pointId,
        tenantSubjectId,
        contentId,
        trackingIdentifier: trackingIdentifier ?? null,
        state: UpdateContentStateEnum.PickUp,
        storageUnitId,
        userId,
        isPickedUpBySender:
          serviceType == ServiceType.HandToHand && isPickedUpBySender,
      },
      {
        onSuccess: () => {
          setEndReservationResult(
            'Page.StorageUnit.Otp.EndReservation.Success'
          );
          setDisplayEndButton(false);
          invalidate([StorageUnitDocument]);
        },
        onError: (error) =>
          showAlertModalError(
            t('Page.StorageUnit.Otp.EndReservation.Error'),
            error
          ),
      }
    );
  };

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading text={t('Page.Login.Loading')} />
      </Flex>
    );
  }

  if (storageUnitError) {
    return (
      <ErrorDetailInfo
        title={t('Error.Error.LoadStorageUnitDetailError')}
        error={storageUnitError}
      />
    );
  }
  return (
    <>
      {endReservationResult ? (
        // TODO: revise this
        // <EndReservationResult
        //   storageUnitId={storageUnitId}
        //   result={endReservationResult}
        //   reservation={reservation as ReservationDto}
        // />
        <Notification testId="end-reservation" message={endReservationResult} />
      ) : null}
      {displayEndButton ? (
        <ButtonWithConfirmation
          testId="end-current-reservation"
          confirmAction={handleEndReservation}
          modalTitle={t('Page.StorageUnit.Otp.EndReservation.ConfirmTitle')}
          modalDescription={t(
            'Page.StorageUnit.Otp.EndReservation.ConfirmDescription'
          )}
          modalConfirm={{
            text: t('Page.StorageUnit.Otp.EndReservation.ConfirmButton'),
            variant: 'primary',
          }}
          cancelText={t('Page.StorageUnit.Otp.EndReservation.CancelButton')}
          zIndexOffset={999}
          renderButton={(onClick: () => void) => (
            <Flex gap="2rem" justifyContent="center" alignItems="center">
              <Button
                testId="end-current-reservation-button"
                onClick={onClick}
                variant="primary"
                size="xl"
                title={t('Page.StorageUnit.Otp.EndReservation.Button')}
              />
              <Box marginTop="4rem">
                {serviceType == ServiceType.HandToHand && (
                  <Checkbox
                    defaultValue={false}
                    testId="is-picked-up-by-sender-checkbox"
                    label={t(
                      'Page.StorageUnit.Otp.EndReservation.IsPickedUpBySender'
                    )}
                    onChange={(checked) => setIsPickedUpBySender(checked)}
                  />
                )}
              </Box>
            </Flex>
          )}
        />
      ) : null}
    </>
  );
};

export default EndCurrentReservation;
