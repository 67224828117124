import { Alert, Button, Flex, Heading, showAlertModalError } from '@conteg/ui';
import Notification from 'components/notification/notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StorageUnitDocument,
  useForcedOpenStorageUnitWithReservationMutation,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

const ForcedOpenUnitWithReservation = ({
  storageUnitId,
}: {
  storageUnitId: string;
}) => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const invalidate = useInvalidateQueries();

  const [formNotice, setFormNotice] = useState<string>('');

  const { mutateAsync: openStorageUnitWithoutPin, isLoading } =
    useForcedOpenStorageUnitWithReservationMutation();

  const handleSuccessOpenStorageUnit = () => {
    setFormNotice('Page.StorageUnit.Actions.Open.WithoutPin.Success');
    invalidate([StorageUnitDocument]);
  };

  const handleOpenStorageUnit = async () => {
    setFormNotice('');
    await openStorageUnitWithoutPin(
      {
        pointId,
        storageUnitId,
      },
      {
        onSuccess: handleSuccessOpenStorageUnit,
        onError: (error) =>
          showAlertModalError(
            t('Page.StorageUnit.Actions.Open.WithoutPin.Error'),
            error
          ),
      }
    );
  };

  return (
    <Flex flexDirection="column" alignItems="center" gap="6rem" mt="5rem">
      <Heading
        variant="h2"
        title={t('Page.StorageUnit.Actions.Open.ForcedStorageOpen.Title')}
      />
      <Alert
        type="warning"
        message={t(
          'Page.StorageUnit.Actions.Open.ForcedStorageOpen.Description'
        )}
      />
      {formNotice && (
        <Notification testId="resend-sms-notification" message={formNotice} />
      )}
      <Button
        title={t('Page.StorageUnit.Actions.Open.ForcedStorageOpen.Open')}
        testId="forced-storage-open-button"
        onClick={handleOpenStorageUnit}
        variant="primary"
        size="xl"
        disabled={isLoading}
      />
    </Flex>
  );
};

export default ForcedOpenUnitWithReservation;
