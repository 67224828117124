import {
  Button,
  ErrorDetailInfo,
  Flex,
  Heading,
  Loading,
  Option,
  showAlertModalError,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { Select } from 'components/select/select';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StorageUnitDocument,
  useChangeBlockingReservationTenantSubjectIdMutation,
  useTenantsQuery,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type FormInputs = {
  tenantSubjectId: string;
};

const ChangeBlockingReservationTenantSubjectId = ({
  reservationId,
}: {
  reservationId: string;
}) => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const invalidate = useInvalidateQueries();
  const [formNotice, setFormNotice] = useState<string>('');

  const formActions = useForm<FormInputs>({
    defaultValues: {
      tenantSubjectId: undefined,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formActions;

  const {
    data: tenants,
    isLoading,
    error: tenantsError,
  } = useTenantsQuery(
    {
      pointId,
    },
    {
      enabled: !!pointId,
      cacheTime: 0,
      staleTime: 0,
      select: (data) => data.tenants,
    }
  );

  const {
    mutate: changeBlockingReservationTenantSubjectId,
    isLoading: isChanging,
  } = useChangeBlockingReservationTenantSubjectIdMutation();

  const tenantOptions: Option[] =
    tenants?.map((data) => ({
      label: data?.tenantName ?? '',
      value: data?.tenantSubjectId ?? '',
    })) ?? [];

  const handleCreateReservation: SubmitHandler<FormInputs> = ({
    tenantSubjectId,
  }) => {
    setFormNotice('');
    changeBlockingReservationTenantSubjectId(
      {
        reservationId,
        tenantSubjectId,
      },
      {
        onSuccess: () => {
          setFormNotice(
            'Page.StorageUnit.Actions.ChangeBlockingReservationTenantSubjectId.Success'
          );
          invalidate([StorageUnitDocument]);
          reset();
        },
        onError: (err) => {
          showAlertModalError(
            t(
              'Page.StorageUnit.Actions.ChangeBlockingReservationTenantSubjectId.Error'
            ),
            err
          );
        },
      }
    );
  };

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading text={t('Loading')} />
      </Flex>
    );
  }

  if (tenantsError) {
    return (
      <ErrorDetailInfo
        error={tenantsError}
        title={t('Error.TenantsLoadError')}
      />
    );
  }

  return (
    <FormProvider {...formActions}>
      <form onSubmit={handleSubmit(handleCreateReservation)}>
        <Flex flexDirection="column" alignItems="center" gap="5rem">
          <Heading
            title={t(
              'Page.StorageUnit.Actions.ChangeBlockingReservationTenantSubjectId.Heading'
            )}
            variant="h2"
          />
          {formNotice && (
            <Notification
              testId="change-blocking-reservation-notification"
              message={formNotice}
            />
          )}
          <Select
            name="tenantSubjectId"
            options={tenantOptions}
            label={t(
              'Page.StorageUnit.Actions.BlockingReservation.Select.Tenant'
            )}
            isNullable={false}
            required
            error={errors?.tenantSubjectId || undefined}
          />
          <Flex alignItems="center" gap="5rem">
            <Button
              testId="change-reservation-confirmation-button"
              variant="primary"
              size="xl"
              title={t(
                'Page.StorageUnit.Actions.Input.BlockingReservation.Create.ConfirmButton'
              )}
              disabled={isChanging}
              type="submit"
            />
          </Flex>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default ChangeBlockingReservationTenantSubjectId;
