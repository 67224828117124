import { Box, Flex, Heading, Loading, styled, Text } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { formatTime } from 'utils/timer-manager/format-time';

export type OneTimePasswordProps = {
  oneTimePassword: string;
  expiration: number;
  isLoading: boolean;
};

const Code = styled.h1`
  font-family: ${({ theme }) => theme.typography.headlines.h1.fontFamily};
  font-weight: ${({ theme }) => theme.typography.headlines.h1.fontWeight};
  font-size: 14rem;
  letter-spacing: ${({ theme }) => theme.typography.headlines.h1.letterSpacing};
  line-height: 16rem;
`;

const OneTimePassword = ({
  oneTimePassword,
  expiration,
  isLoading,
}: OneTimePasswordProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Box mt="6rem">
        <Loading />
      </Box>
    );
  }

  return (
    <Flex flexDirection="column" gap="2rem" alignItems="center" mt="6rem">
      <Box mb="4rem" textAlign="center">
        <Heading
          testId="otp-heading"
          variant="h2"
          title={t('Page.StorageUnit.Otp.Title')}
        />
        <Text variant="large" content={t('Page.StorageUnit.Otp.SubTitle')} />
      </Box>

      <Code data-test="one-time-password">
        {oneTimePassword.split('').join(' ')}
      </Code>

      <Heading
        testId="otp-countdown"
        variant="h3"
        title={formatTime(expiration)}
      />
    </Flex>
  );
};

export default OneTimePassword;
