import { Box, Button, Flex, Input, showAlertModalError } from '@conteg/ui';
import Notification from 'components/notification/notification';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StorageUnitDocument,
  StorageUnitStatus,
  useRequestMaintenanceStorageUnitMutation,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useOnStatusChangeHandler } from 'utils/hooks/storage-units-event-handlers';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type FormInputs = {
  reason: string;
};

const RequestMaintenance = ({ storageUnitId }: { storageUnitId: string }) => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const invalidate = useInvalidateQueries();

  const onStatusChange = useOnStatusChangeHandler();

  const { mutateAsync, isLoading: isMutating } =
    useRequestMaintenanceStorageUnitMutation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      reason: '',
    },
  });

  const [formNotice, setFormNotice] = useState<string>('');

  const handleRequestMaintenance: SubmitHandler<FormInputs> = async (data) => {
    setFormNotice('');
    await mutateAsync(
      {
        pointId,
        storageUnitId,
        statusChangeReason: data.reason,
      },
      {
        onSuccess: () => {
          setFormNotice('Page.StorageUnit.Actions.Maintenance.Success');
          invalidate([StorageUnitDocument]);
          if (onStatusChange)
            onStatusChange(storageUnitId, StorageUnitStatus.Broken);
          reset();
        },
        onError: (error) =>
          showAlertModalError(
            t('Page.StorageUnit.Actions.Maintenance.Error'),
            error
          ),
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(handleRequestMaintenance)}>
      <Flex flexDirection="column" gap="6rem" mt="5rem">
        {formNotice && (
          <Notification testId="request-maintenance" message={formNotice} />
        )}
        <Input
          testId="request-maintenance-reason"
          label={t('Page.StorageUnit.Actions.Maintenance.Reason')}
          placeholder={t('Page.StorageUnit.Actions.Maintenance.Reason')}
          error={errors.reason?.message && t(errors.reason?.message)}
          {...register('reason', { required: 'Error.Form.Required' })}
        />
        <Box mx="auto">
          <Button
            testId="open-without-pin-button"
            variant="primary"
            size="xl"
            title={t('Page.StorageUnit.Actions.Maintenance')}
            disabled={isMutating}
          />
        </Box>
      </Flex>
    </form>
  );
};

export default RequestMaintenance;
