import { Button, Flex, Heading, showAlertModalError } from '@conteg/ui';
import Error from 'components/error/error';
import Notification from 'components/notification/notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResendSmsMutation } from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';

const ResendSms = ({ reservationId }: { reservationId: string }) => {
  const { t } = useTranslation();
  const { mutateAsync: resendSms, isLoading, error } = useResendSmsMutation();
  const { pointId } = usePoint();

  const [formNotice, setFormNotice] = useState<string>('');

  const handleResendSms = () => {
    setFormNotice('');
    resendSms(
      {
        pointId,
        reservationId,
      },
      {
        onSuccess: () => {
          setFormNotice('Page.StorageUnit.Actions.ResendSms.Success');
        },
        onError: (error) => {
          showAlertModalError(
            t('Page.StorageUnit.Actions.ResendSms.Error'),
            error
          );
        },
      }
    );
  };

  if (error) {
    return <Error message="Error.DataFetchFailed" />;
  }

  return (
    <Flex flexDirection="column" gap="6rem" alignItems="center" mt="6rem">
      {formNotice && (
        <Notification testId="resend-sms-notification" message={formNotice} />
      )}
      <Heading
        variant="h2"
        title={t('Page.StorageUnit.Actions.ResendSms.Heading')}
      />
      <Button
        testId="resend-button"
        variant="primary"
        size="xl"
        title={t('Page.StorageUnit.Actions.ResendSms.Send')}
        disabled={isLoading}
        onClick={handleResendSms}
      />
    </Flex>
  );
};

export default ResendSms;
