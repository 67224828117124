import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetcher } from 'utils/gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  UUID: any;
};

export enum AccessCredentialAction {
  Peek = 'PEEK',
  Pickup = 'PICKUP',
  StockIn = 'STOCK_IN',
  StockOut = 'STOCK_OUT',
  Store = 'STORE'
}

export type AccessCredentialsDto = {
  __typename?: 'AccessCredentialsDto';
  accessCredentialActions: Array<AccessCredentialAction>;
  id: Scalars['UUID'];
  installationId?: Maybe<Scalars['UUID']>;
  personIdentifier?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pinCode?: Maybe<Scalars['String']>;
  pointId?: Maybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  reservationId?: Maybe<Scalars['UUID']>;
  serviceType: ServiceType;
  tenantSubjectId: Scalars['UUID'];
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export type AllocationDto = {
  __typename?: 'AllocationDto';
  content: ContentDto;
  endedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  refusedAt?: Maybe<Scalars['DateTime']>;
  reservation: ReservationDtoV2;
  startedAt: Scalars['DateTime'];
  status: AllocationStatus;
  storageUnitId: Scalars['UUID'];
  terminatedAt?: Maybe<Scalars['DateTime']>;
};

export enum AllocationStatus {
  Ended = 'ENDED',
  Expired = 'EXPIRED',
  Refused = 'REFUSED',
  Started = 'STARTED',
  Terminated = 'TERMINATED'
}

export type AppConfigurationDto = {
  __typename?: 'AppConfigurationDto';
  callCenterPhoneNumber: Scalars['String'];
  isPointOnline: Scalars['Boolean'];
  languages: Array<LanguageDto>;
  pointFullName: Scalars['String'];
  projectId: Scalars['UUID'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AreaDto = {
  __typename?: 'AreaDto';
  areaTenants?: Maybe<Array<AreaTenantDto>>;
  deactivationReason?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  isDefaultSharedArea: Scalars['Boolean'];
  point?: Maybe<PointDto>;
  pointId: Scalars['UUID'];
  storageUnits?: Maybe<Array<StorageUnitDto>>;
  tenantSubjectIds?: Maybe<Array<Scalars['UUID']>>;
};

export type AreaTenantDto = {
  __typename?: 'AreaTenantDto';
  area?: Maybe<AreaDto>;
  areaId: Scalars['UUID'];
  id: Scalars['UUID'];
  tenantSubjectId: Scalars['UUID'];
};

export enum BlockingReservationReason {
  BoxCannotBeClosed = 'BOX_CANNOT_BE_CLOSED',
  BoxDidNotOpen = 'BOX_DID_NOT_OPEN',
  BrokenDoor = 'BROKEN_DOOR',
  BrokenLock = 'BROKEN_LOCK',
  DirtyBox = 'DIRTY_BOX',
  MissingContent = 'MISSING_CONTENT',
  Other = 'OTHER',
  UnidentifiedContent = 'UNIDENTIFIED_CONTENT',
  WrongContent = 'WRONG_CONTENT'
}

export type CompanyInfoDto = {
  __typename?: 'CompanyInfoDto';
  id: Scalars['UUID'];
  name: Scalars['String'];
  subjectId: Scalars['UUID'];
};

export type CompanyTableItemDto = {
  __typename?: 'CompanyTableItemDto';
  id: Scalars['UUID'];
  isProvider: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  logoUrlUpdatedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  subjectId: Scalars['UUID'];
  teamsMembersCount: Scalars['Int'];
  type: CompanyType;
};

export type CompanyTableItemDtoPaginatedListDto = {
  __typename?: 'CompanyTableItemDtoPaginatedListDto';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  items: Array<CompanyTableItemDto>;
  pageIndex: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum CompanyType {
  Big = 'BIG',
  Conteg = 'CONTEG',
  DevelopmentAndTesting = 'DEVELOPMENT_AND_TESTING',
  Small = 'SMALL'
}

export type ContentDto = {
  __typename?: 'ContentDto';
  allocation?: Maybe<AllocationDto>;
  dimensions?: Maybe<DimensionsDto>;
  evictedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  pickedUpAt?: Maybe<Scalars['DateTime']>;
  requiredStorageUnitSizeId?: Maybe<Scalars['UUID']>;
  reservation?: Maybe<ReservationDtoV2>;
  reservationId: Scalars['UUID'];
  status: ContentStatus;
  stockedInAt?: Maybe<Scalars['DateTime']>;
  stockedOutAt?: Maybe<Scalars['DateTime']>;
  storedAt?: Maybe<Scalars['DateTime']>;
  trackingIdentifiers: Array<TrackingIdentifierDto>;
};

export enum ContentStatus {
  CancelledNoSuitableBox = 'CANCELLED_NO_SUITABLE_BOX',
  CancelledOversized = 'CANCELLED_OVERSIZED',
  Evicted = 'EVICTED',
  PickedUp = 'PICKED_UP',
  StockedIn = 'STOCKED_IN',
  StockedOut = 'STOCKED_OUT',
  Stored = 'STORED',
  WaitingForAllocation = 'WAITING_FOR_ALLOCATION'
}

export type CoolingDto = {
  __typename?: 'CoolingDto';
  id: Scalars['UUID'];
};

export type CreateBlockingReservationRequestInput = {
  phoneNumber?: InputMaybe<Scalars['String']>;
  pointId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  reason: BlockingReservationReason;
  reasonDescription?: InputMaybe<Scalars['String']>;
  serviceType: ServiceTypeEnum;
  storageUnitId: Scalars['UUID'];
  tenantSubjectId: Scalars['UUID'];
};

export type CreateDeliveryReservationOutputDto = {
  __typename?: 'CreateDeliveryReservationOutputDto';
  pinCode: Scalars['String'];
  reservationId: Scalars['UUID'];
};

export type CreateDeliveryReservationRequestInput = {
  contentDepth: Scalars['Int'];
  contentHeight: Scalars['Int'];
  contentWidth: Scalars['Int'];
  duration: Scalars['String'];
  expiration: Scalars['DateTime'];
  from: Scalars['DateTime'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  pinCode?: InputMaybe<Scalars['String']>;
  pointId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  tenantSubjectId: Scalars['UUID'];
  trackingIdentifier: Scalars['String'];
};

export enum Currency {
  Czk = 'CZK',
  Default = 'DEFAULT',
  Eur = 'EUR',
  Gbp = 'GBP',
  Ron = 'RON',
  Rub = 'RUB',
  Usd = 'USD'
}

export type DeviceDto = {
  __typename?: 'DeviceDto';
  cooling?: Maybe<CoolingDto>;
  deviceModelNumber?: Maybe<Scalars['String']>;
  deviceType: DeviceType;
  id: Scalars['UUID'];
  installation?: Maybe<InstallationDto>;
  installationId?: Maybe<Scalars['UUID']>;
  kioskPc?: Maybe<KioskPcDto>;
  lock?: Maybe<LockDto>;
  lockController?: Maybe<LockControllerDto>;
  lockNetworkUnit?: Maybe<LockNetworkUnitDto>;
  note?: Maybe<Scalars['String']>;
  paymentTerminal?: Maybe<PaymentTerminalDto>;
  pointId?: Maybe<Scalars['UUID']>;
  printer?: Maybe<PrinterDto>;
  relay?: Maybe<RelayDto>;
  router?: Maybe<RouterDto>;
  touchDisplay?: Maybe<TouchDisplayDto>;
};

export enum DeviceType {
  Cooling = 'COOLING',
  KioskPc = 'KIOSK_PC',
  Lock = 'LOCK',
  LockController = 'LOCK_CONTROLLER',
  LockNetworkUnit = 'LOCK_NETWORK_UNIT',
  PaymentTerminal = 'PAYMENT_TERMINAL',
  Printer = 'PRINTER',
  Relay = 'RELAY',
  Router = 'ROUTER',
  TouchDisplay = 'TOUCH_DISPLAY'
}

export type DimensionsDto = {
  __typename?: 'DimensionsDto';
  depth: Scalars['Int'];
  height: Scalars['Int'];
  preserveVerticalOrientation: Scalars['Boolean'];
  width: Scalars['Int'];
};

export enum DisplayRotationDirection {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type EndUserAccessPinInputDtoInput = {
  accessPin: Scalars['String'];
};

export type GqlStorageUnitDto = {
  __typename?: 'GqlStorageUnitDto';
  allocation?: Maybe<AllocationDto>;
  company?: Maybe<CompanyInfoDto>;
  hasReservation: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  reservation?: Maybe<ReservationDtoV2>;
  storageUnit: StorageUnitDto;
};

export type GqlStorageUnitListDto = {
  __typename?: 'GqlStorageUnitListDto';
  allocationFrom?: Maybe<Scalars['DateTime']>;
  allocationTo?: Maybe<Scalars['DateTime']>;
  isDeliverySubmission: Scalars['Boolean'];
  isEmpty: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  requiredStorageUnitFeatures: Array<StorageUnitFeature>;
  storageUnit: StorageUnitDto;
  trackingIdentifier?: Maybe<Scalars['String']>;
};

export type GqlVerifyAccessPinDto = {
  __typename?: 'GqlVerifyAccessPinDto';
  bindingFee?: Maybe<Scalars['Float']>;
};

export type InstallationDto = {
  __typename?: 'InstallationDto';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  deactivationReason?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  geoRegion?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isIndoor: Scalars['Boolean'];
  isParkingPossible: Scalars['Boolean'];
  isVisuallyImpairedAccessible: Scalars['Boolean'];
  isWheelchairAccessible: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  locationDescription?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  openingHourExceptionsSummary?: Maybe<Scalars['String']>;
  operatingHours?: Maybe<Array<OperatingHoursDto>>;
  ownerSubjectId: Scalars['UUID'];
  partOfCity?: Maybe<Scalars['String']>;
  points?: Maybe<Array<PointDto>>;
  postalCode?: Maybe<Scalars['String']>;
  projectId: Scalars['UUID'];
  providerSubjectId: Scalars['UUID'];
  region?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  streetOrientationNumber?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type KioskPcDto = {
  __typename?: 'KioskPcDto';
  ipAddress?: Maybe<Scalars['String']>;
};

export enum Language {
  Cs = 'CS',
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Sk = 'SK',
  Uk = 'UK'
}

export type LanguageDto = {
  __typename?: 'LanguageDto';
  code: Scalars['String'];
  id: Scalars['UUID'];
  isDefaultLanguage: Scalars['Boolean'];
  name: Scalars['String'];
};

export type LockControllerDto = {
  __typename?: 'LockControllerDto';
  description?: Maybe<Scalars['String']>;
};

export type LockDto = {
  __typename?: 'LockDto';
  fallbackLockOpenAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  lastLockOpenRequestedAt?: Maybe<Scalars['DateTime']>;
  lastStatus: LockStatus;
  lockIdentifier?: Maybe<Scalars['String']>;
  storageUnit?: Maybe<StorageUnitDto>;
  storageUnitId: Scalars['UUID'];
};

export type LockNetworkUnitDto = {
  __typename?: 'LockNetworkUnitDto';
  ipAddress?: Maybe<Scalars['String']>;
  port: Scalars['Int'];
};

export enum LockStatus {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Opened = 'OPENED'
}

export type Mutation = {
  __typename?: 'Mutation';
  changeBlockingReservationTenantSubjectId: Scalars['Boolean'];
  changeStorageUnitStatus: StorageUnitDto;
  createBlockingReservationRequestV2: Scalars['Boolean'];
  createDeliveryReservation: CreateDeliveryReservationOutputDto;
  forcedOpenStorageUnitWithReservation: Scalars['Boolean'];
  generateStorageUnitOneTimePassword: OneTimePasswordDto;
  openEmptyStorageUnit: Scalars['Boolean'];
  openEmptyStorageUnits: Scalars['Boolean'];
  openServiceModule: Scalars['Boolean'];
  openStorageUnit: OpenStorageUnitDto;
  requestMaintenanceStorageUnit: Scalars['UUID'];
  resendSms: Scalars['Boolean'];
  setStorageUnitStatus: StorageUnitDto;
  terminateAllocationV2: Scalars['Boolean'];
  updateContentState: Scalars['Boolean'];
  updateReservationDuration: Scalars['Boolean'];
  verifyAccessPin: GqlVerifyAccessPinDto;
};


export type MutationChangeBlockingReservationTenantSubjectIdArgs = {
  reservationId: Scalars['UUID'];
  tenantSubjectId: Scalars['UUID'];
};


export type MutationChangeStorageUnitStatusArgs = {
  status: StorageUnitStatus;
  statusChangeReason: Scalars['String'];
  storageUnitId: Scalars['UUID'];
};


export type MutationCreateBlockingReservationRequestV2Args = {
  input: CreateBlockingReservationRequestInput;
};


export type MutationCreateDeliveryReservationArgs = {
  request: CreateDeliveryReservationRequestInput;
};


export type MutationForcedOpenStorageUnitWithReservationArgs = {
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
};


export type MutationGenerateStorageUnitOneTimePasswordArgs = {
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
};


export type MutationOpenEmptyStorageUnitArgs = {
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
};


export type MutationOpenEmptyStorageUnitsArgs = {
  pointId: Scalars['UUID'];
  storageUnitIds: Array<Scalars['UUID']>;
};


export type MutationOpenServiceModuleArgs = {
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
};


export type MutationOpenStorageUnitArgs = {
  endUser: EndUserAccessPinInputDtoInput;
  isBlockingReservation: Scalars['Boolean'];
  pointId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  serviceType: ServiceTypeEnum;
  singleTenantVerification: Scalars['Boolean'];
  storageUnitId?: InputMaybe<Scalars['UUID']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']>;
};


export type MutationRequestMaintenanceStorageUnitArgs = {
  pointId: Scalars['UUID'];
  statusChangeReason: Scalars['String'];
  storageUnitId: Scalars['UUID'];
};


export type MutationResendSmsArgs = {
  pointId: Scalars['UUID'];
  reservationId: Scalars['UUID'];
};


export type MutationSetStorageUnitStatusArgs = {
  setStorageUnitStatusDto?: InputMaybe<PartialUpdateStorageUnitDtoInput>;
  storageUnitId: Scalars['UUID'];
};


export type MutationTerminateAllocationV2Args = {
  storageUnitId: Scalars['UUID'];
};


export type MutationUpdateContentStateArgs = {
  contentId?: InputMaybe<Scalars['UUID']>;
  isPickedUpBySender?: InputMaybe<Scalars['Boolean']>;
  pointId: Scalars['UUID'];
  state: UpdateContentStateEnum;
  storageUnitId?: InputMaybe<Scalars['UUID']>;
  tenantSubjectId: Scalars['UUID'];
  trackingIdentifier?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['UUID']>;
};


export type MutationUpdateReservationDurationArgs = {
  reservationId: Scalars['UUID'];
  to?: InputMaybe<Scalars['DateTime']>;
};


export type MutationVerifyAccessPinArgs = {
  accessPin: Scalars['String'];
  pointId: Scalars['UUID'];
  projectId: Scalars['UUID'];
  serviceType: ServiceTypeEnum;
  storageUnitId: Scalars['UUID'];
  tenantSubjectId: Scalars['UUID'];
};

export type OneTimePasswordDto = {
  __typename?: 'OneTimePasswordDto';
  oneTimePassword?: Maybe<Scalars['String']>;
  validTo: Scalars['DateTime'];
};

export type OpenStorageUnitDto = {
  __typename?: 'OpenStorageUnitDto';
  bindingFee?: Maybe<Scalars['Float']>;
  storageUnitId: Scalars['UUID'];
};

export type OperatingHoursDto = {
  __typename?: 'OperatingHoursDto';
  dayOfWeek: Scalars['Int'];
  endTime?: Maybe<Scalars['TimeSpan']>;
  id: Scalars['UUID'];
  installation?: Maybe<InstallationDto>;
  installationId: Scalars['UUID'];
  isAllDayClosed: Scalars['Boolean'];
  isUnexpected: Scalars['Boolean'];
  startTime?: Maybe<Scalars['TimeSpan']>;
  unexpectedDateTime?: Maybe<Scalars['DateTime']>;
};

export type PartialUpdateStorageUnitDtoInput = {
  areaId?: InputMaybe<Scalars['UUID']>;
  features: Array<StorageUnitFeature>;
  statusChangeReason?: InputMaybe<Scalars['String']>;
  storageUnitStatus?: InputMaybe<StorageUnitStatus>;
};

export type PaymentTerminalDto = {
  __typename?: 'PaymentTerminalDto';
  currency: Currency;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  lastLifeCheck?: Maybe<Scalars['DateTime']>;
  pid?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  id: Scalars['UUID'];
  installationId: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type PointDto = {
  __typename?: 'PointDto';
  areas?: Maybe<Array<AreaDto>>;
  deactivationReason?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<DeviceDto>>;
  id: Scalars['UUID'];
  imageUrl?: Maybe<Scalars['String']>;
  installation?: Maybe<InstallationDto>;
  installationId: Scalars['UUID'];
  internetSpeedDownloadBytes?: Maybe<Scalars['Float']>;
  internetSpeedMeasuredAt?: Maybe<Scalars['DateTime']>;
  internetSpeedUploadBytes?: Maybe<Scalars['Float']>;
  isActive: Scalars['Boolean'];
  isConfigured: Scalars['Boolean'];
  isManualLayoutManagement: Scalars['Boolean'];
  isOnline?: Maybe<Scalars['Boolean']>;
  isOpenForEdit: Scalars['Boolean'];
  lastOnlineAt?: Maybe<Scalars['DateTime']>;
  localApplicationVersion?: Maybe<Scalars['String']>;
  pointIdentifier?: Maybe<Scalars['String']>;
  pointPrefix?: Maybe<Scalars['String']>;
  pointRegistrationNumber?: Maybe<Scalars['String']>;
  pointSequenceNumber: Scalars['Int'];
  storageColumns?: Maybe<Array<StorageColumnDto>>;
};

export type PrinterDto = {
  __typename?: 'PrinterDto';
  device?: Maybe<DeviceDto>;
  deviceId: Scalars['UUID'];
  id: Scalars['UUID'];
  status?: Maybe<Array<PrinterStatus>>;
};

export enum PrinterStatus {
  ConnectionNoBixolonPrinter = 'CONNECTION_NO_BIXOLON_PRINTER',
  ConnectionNoConnectedPrinter = 'CONNECTION_NO_CONNECTED_PRINTER',
  ConnectionPortOpenError = 'CONNECTION_PORT_OPEN_ERROR',
  CoverOpen = 'COVER_OPEN',
  CutterError = 'CUTTER_ERROR',
  Ok = 'OK',
  Overheatting = 'OVERHEATTING',
  PaperEmpty = 'PAPER_EMPTY',
  PaperIn = 'PAPER_IN',
  PaperJam = 'PAPER_JAM',
  PaperNearEnd = 'PAPER_NEAR_END',
  PaperToBeTaken = 'PAPER_TO_BE_TAKEN',
  Unknown = 'UNKNOWN',
  UnrecoverableError = 'UNRECOVERABLE_ERROR'
}

export type Query = {
  __typename?: 'Query';
  appConfig: AppConfigurationDto;
  companies: CompanyTableItemDtoPaginatedListDto;
  points: Array<Maybe<PointDto>>;
  storageUnit: GqlStorageUnitDto;
  storageUnitSizes: Array<StorageUnitSizeDto>;
  storageUnits: Array<Maybe<GqlStorageUnitListDto>>;
  tenants: Array<Maybe<TenantDto>>;
  tenantsByProject: Array<Maybe<TenantByProjectDto>>;
  userRoles?: Maybe<Array<UserRoleDto>>;
  userTypes?: Maybe<Array<Scalars['String']>>;
};


export type QueryAppConfigArgs = {
  pointId: Scalars['UUID'];
};


export type QueryCompaniesArgs = {
  allInOnePage?: InputMaybe<Scalars['Boolean']>;
  businessTypeId?: InputMaybe<Scalars['ID']>;
  callCenterTeamId?: InputMaybe<Scalars['UUID']>;
  pageIndex?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  pointId?: InputMaybe<Scalars['UUID']>;
};


export type QueryPointsArgs = {
  installationId?: InputMaybe<Scalars['UUID']>;
};


export type QueryStorageUnitArgs = {
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
};


export type QueryStorageUnitsArgs = {
  pointId: Scalars['UUID'];
};


export type QueryTenantsArgs = {
  pointId: Scalars['UUID'];
  serviceType?: InputMaybe<ServiceTypeEnum>;
};


export type QueryTenantsByProjectArgs = {
  projectId: Scalars['UUID'];
};


export type QueryUserRolesArgs = {
  userId: Scalars['UUID'];
};

export type RelayDto = {
  __typename?: 'RelayDto';
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ReservationDtoV2 = {
  __typename?: 'ReservationDtoV2';
  accessCredentials?: Maybe<Array<AccessCredentialsDto>>;
  allocations?: Maybe<Array<AllocationDto>>;
  blockingReservationReason?: Maybe<BlockingReservationReason>;
  blockingReservationReasonDescription?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  contents?: Maybe<Array<ContentDto>>;
  createdAt: Scalars['DateTime'];
  designatedStorageUnitId?: Maybe<Scalars['UUID']>;
  duration?: Maybe<Scalars['String']>;
  expiration: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  from: Scalars['DateTime'];
  id: Scalars['UUID'];
  isArchived: Scalars['Boolean'];
  isBlockingReservation: Scalars['Boolean'];
  isDeliverySubmission: Scalars['Boolean'];
  languageCode?: Maybe<Language>;
  pickupDue?: Maybe<Scalars['DateTime']>;
  projectId: Scalars['UUID'];
  requiredStorageUnitFeatures: Array<StorageUnitFeature>;
  serviceType: ServiceType;
  status: ReservationStatus;
  tenantSubjectId: Scalars['UUID'];
  terminatedAt?: Maybe<Scalars['DateTime']>;
  to: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ReservationStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Ended = 'ENDED',
  Expired = 'EXPIRED',
  Started = 'STARTED'
}

export type RouterDto = {
  __typename?: 'RouterDto';
  localIpAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  vpnIpAddress?: Maybe<Scalars['String']>;
};

export enum ServiceType {
  BagToBox = 'BAG_TO_BOX',
  Delivery = 'DELIVERY',
  HandToHand = 'HAND_TO_HAND'
}

export enum ServiceTypeEnum {
  BagToBox = 'BAG_TO_BOX',
  Delivery = 'DELIVERY',
  HandToHand = 'HAND_TO_HAND'
}

export type StorageColumnDto = {
  __typename?: 'StorageColumnDto';
  columnNumber: Scalars['Int'];
  depth: Scalars['Int'];
  id: Scalars['UUID'];
  isActive: Scalars['Boolean'];
  maxHorizontalUnits: Scalars['Int'];
  maxVerticalUnits: Scalars['Int'];
  point?: Maybe<PointDto>;
  pointId: Scalars['UUID'];
  storageUnits?: Maybe<Array<StorageUnitDto>>;
};

export type StorageUnitDto = {
  __typename?: 'StorageUnitDto';
  area?: Maybe<AreaDto>;
  areaId: Scalars['UUID'];
  features: Array<StorageUnitFeature>;
  id: Scalars['UUID'];
  isLayoutManaged: Scalars['Boolean'];
  lastLockStatus?: Maybe<LockStatus>;
  lock?: Maybe<LockDto>;
  name?: Maybe<Scalars['String']>;
  startingHorizontalCoordinate: Scalars['Int'];
  startingVerticalCoordinate: Scalars['Int'];
  statusChangeReason?: Maybe<Scalars['String']>;
  storageColumn?: Maybe<StorageColumnDto>;
  storageColumnId: Scalars['UUID'];
  storageUnitSize?: Maybe<StorageUnitSizeDto>;
  storageUnitSizeId: Scalars['UUID'];
  storageUnitStatus: StorageUnitStatus;
};

export enum StorageUnitFeature {
  AcSocket = 'AC_SOCKET',
  Cooling = 'COOLING'
}

export type StorageUnitSizeDto = {
  __typename?: 'StorageUnitSizeDto';
  friendlyName?: Maybe<Scalars['String']>;
  horizontalUnits: Scalars['Int'];
  id: Scalars['UUID'];
  innerDepth: Scalars['Int'];
  innerHeight: Scalars['Int'];
  innerWidth: Scalars['Int'];
  isServiceModule: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  outerDepth: Scalars['Int'];
  outerHeight: Scalars['Int'];
  outerWidth: Scalars['Int'];
  storageUnits?: Maybe<Array<StorageUnitDto>>;
  verticalUnits: Scalars['Int'];
};

export enum StorageUnitStatus {
  Blocked = 'BLOCKED',
  Broken = 'BROKEN',
  Usable = 'USABLE'
}

export type StorageUnitSubscriptionDto = {
  __typename?: 'StorageUnitSubscriptionDto';
  id: Scalars['UUID'];
  label: Scalars['String'];
  state: LockStatus;
};

export type Subscription = {
  __typename?: 'Subscription';
  pointStateChanged: Point;
  storageUnitStateChanged?: Maybe<StorageUnitSubscriptionDto>;
};


export type SubscriptionStorageUnitStateChangedArgs = {
  pointId: Scalars['UUID'];
};

export type TenantByProjectDto = {
  __typename?: 'TenantByProjectDto';
  isProjectOwner: Scalars['Boolean'];
  maxPeriod: Scalars['String'];
  projectId: Scalars['UUID'];
  tenantName?: Maybe<Scalars['String']>;
  tenantServiceType: ServiceTypeEnum;
  tenantSubjectId: Scalars['UUID'];
};

export type TenantDto = {
  __typename?: 'TenantDto';
  maxPeriod: Scalars['String'];
  projectId: Scalars['UUID'];
  tenantName?: Maybe<Scalars['String']>;
  tenantServiceType: ServiceTypeEnum;
  tenantSubjectId: Scalars['UUID'];
};

export type TouchDisplayDto = {
  __typename?: 'TouchDisplayDto';
  displayRotation: DisplayRotationDirection;
  screenHeight?: Maybe<Scalars['Int']>;
  screenWidth?: Maybe<Scalars['Int']>;
  touchLayerRotation: DisplayRotationDirection;
};

export type TrackingIdentifierDto = {
  __typename?: 'TrackingIdentifierDto';
  identifier: Scalars['String'];
};

export enum UpdateContentStateEnum {
  PickUp = 'PICK_UP',
  StockOut = 'STOCK_OUT'
}

export type UserRoleDto = {
  __typename?: 'UserRoleDto';
  roleId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

export type AllocationDtoFragment = { __typename?: 'AllocationDto', id: any, storageUnitId: any, status: AllocationStatus, content: { __typename?: 'ContentDto', id: any, storedAt?: string | null, trackingIdentifiers: Array<{ __typename?: 'TrackingIdentifierDto', identifier: string }> }, reservation: { __typename?: 'ReservationDtoV2', id: any, tenantSubjectId: any, isBlockingReservation: boolean, isDeliverySubmission: boolean, blockingReservationReasonDescription?: string | null, blockingReservationReason?: BlockingReservationReason | null, serviceType: ServiceType, languageCode?: Language | null, status: ReservationStatus, pickupDue?: string | null, createdAt: string, cancelledAt?: string | null, duration?: string | null, expiration: string, updatedAt: string, expiredAt?: string | null, terminatedAt?: string | null, finishedAt?: string | null, from: string, to: string, requiredStorageUnitFeatures: Array<StorageUnitFeature>, accessCredentials?: Array<{ __typename?: 'AccessCredentialsDto', id: any, phoneNumber?: string | null }> | null } };

export type StorageUnitListFragment = { __typename?: 'GqlStorageUnitListDto', isEmpty: boolean, allocationFrom?: string | null, allocationTo?: string | null, trackingIdentifier?: string | null, phoneNumber?: string | null, requiredStorageUnitFeatures: Array<StorageUnitFeature>, storageUnit: { __typename?: 'StorageUnitDto', id: any, name?: string | null, storageUnitStatus: StorageUnitStatus, features: Array<StorageUnitFeature>, lock?: { __typename?: 'LockDto', lastStatus: LockStatus } | null, storageUnitSize?: { __typename?: 'StorageUnitSizeDto', isServiceModule: boolean } | null } };

export type StorageUnitSizeFragment = { __typename?: 'StorageUnitSizeDto', isServiceModule: boolean, innerWidth: number, innerHeight: number, innerDepth: number };

export type StorageUnitDtoFragment = { __typename?: 'GqlStorageUnitDto', hasReservation: boolean, phoneNumber?: string | null, allocation?: { __typename?: 'AllocationDto', id: any, storageUnitId: any, status: AllocationStatus, content: { __typename?: 'ContentDto', id: any, storedAt?: string | null, trackingIdentifiers: Array<{ __typename?: 'TrackingIdentifierDto', identifier: string }> }, reservation: { __typename?: 'ReservationDtoV2', id: any, tenantSubjectId: any, isBlockingReservation: boolean, isDeliverySubmission: boolean, blockingReservationReasonDescription?: string | null, blockingReservationReason?: BlockingReservationReason | null, serviceType: ServiceType, languageCode?: Language | null, status: ReservationStatus, pickupDue?: string | null, createdAt: string, cancelledAt?: string | null, duration?: string | null, expiration: string, updatedAt: string, expiredAt?: string | null, terminatedAt?: string | null, finishedAt?: string | null, from: string, to: string, requiredStorageUnitFeatures: Array<StorageUnitFeature>, accessCredentials?: Array<{ __typename?: 'AccessCredentialsDto', id: any, phoneNumber?: string | null }> | null } } | null, storageUnit: { __typename?: 'StorageUnitDto', id: any, name?: string | null, storageUnitStatus: StorageUnitStatus, features: Array<StorageUnitFeature>, lock?: { __typename?: 'LockDto', lastStatus: LockStatus } | null, storageUnitSize?: { __typename?: 'StorageUnitSizeDto', isServiceModule: boolean, innerWidth: number, innerHeight: number, innerDepth: number } | null }, company?: { __typename?: 'CompanyInfoDto', name: string } | null };

export type ChangeBlockingReservationTenantSubjectIdMutationVariables = Exact<{
  reservationId: Scalars['UUID'];
  tenantSubjectId: Scalars['UUID'];
}>;


export type ChangeBlockingReservationTenantSubjectIdMutation = { __typename?: 'Mutation', changeBlockingReservationTenantSubjectId: boolean };

export type ChangeStorageUnitStatusMutationVariables = Exact<{
  storageUnitId: Scalars['UUID'];
  status: StorageUnitStatus;
  statusChangeReason: Scalars['String'];
}>;


export type ChangeStorageUnitStatusMutation = { __typename?: 'Mutation', changeStorageUnitStatus: { __typename?: 'StorageUnitDto', id: any, storageUnitStatus: StorageUnitStatus } };

export type CreateBlockingReservationRequestV2MutationVariables = Exact<{
  input: CreateBlockingReservationRequestInput;
}>;


export type CreateBlockingReservationRequestV2Mutation = { __typename?: 'Mutation', createBlockingReservationRequestV2: boolean };

export type CreateDeliveryReservationMutationVariables = Exact<{
  request: CreateDeliveryReservationRequestInput;
}>;


export type CreateDeliveryReservationMutation = { __typename?: 'Mutation', createDeliveryReservation: { __typename?: 'CreateDeliveryReservationOutputDto', pinCode: string } };

export type ForcedOpenStorageUnitWithReservationMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
}>;


export type ForcedOpenStorageUnitWithReservationMutation = { __typename?: 'Mutation', forcedOpenStorageUnitWithReservation: boolean };

export type GenerateStorageUnitOneTimePasswordMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
}>;


export type GenerateStorageUnitOneTimePasswordMutation = { __typename?: 'Mutation', generateStorageUnitOneTimePassword: { __typename?: 'OneTimePasswordDto', oneTimePassword?: string | null, validTo: string } };

export type OpenEmptyStorageUnitMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
}>;


export type OpenEmptyStorageUnitMutation = { __typename?: 'Mutation', openEmptyStorageUnit: boolean };

export type OpenEmptyStorageUnitsMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitIds: Array<Scalars['UUID']> | Scalars['UUID'];
}>;


export type OpenEmptyStorageUnitsMutation = { __typename?: 'Mutation', openEmptyStorageUnits: boolean };

export type OpenServiceModuleMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
}>;


export type OpenServiceModuleMutation = { __typename?: 'Mutation', openServiceModule: boolean };

export type OpenStorageUnitMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  endUser: EndUserAccessPinInputDtoInput;
  isBlockingReservation: Scalars['Boolean'];
  storageUnitId?: InputMaybe<Scalars['UUID']>;
  projectId: Scalars['UUID'];
  serviceType: ServiceTypeEnum;
  tenantSubjectId?: InputMaybe<Scalars['UUID']>;
  singleTenantVerification: Scalars['Boolean'];
}>;


export type OpenStorageUnitMutation = { __typename?: 'Mutation', openStorageUnit: { __typename?: 'OpenStorageUnitDto', bindingFee?: number | null, storageUnitId: any } };

export type RequestMaintenanceStorageUnitMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
  statusChangeReason: Scalars['String'];
}>;


export type RequestMaintenanceStorageUnitMutation = { __typename?: 'Mutation', requestMaintenanceStorageUnit: any };

export type ResendSmsMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  reservationId: Scalars['UUID'];
}>;


export type ResendSmsMutation = { __typename?: 'Mutation', resendSms: boolean };

export type TerminateAllocationV2MutationVariables = Exact<{
  storageUnitId: Scalars['UUID'];
}>;


export type TerminateAllocationV2Mutation = { __typename?: 'Mutation', terminateAllocationV2: boolean };

export type UpdateContentStateMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  tenantSubjectId: Scalars['UUID'];
  trackingIdentifier?: InputMaybe<Scalars['String']>;
  state: UpdateContentStateEnum;
  contentId?: InputMaybe<Scalars['UUID']>;
  storageUnitId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
  isPickedUpBySender?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateContentStateMutation = { __typename?: 'Mutation', updateContentState: boolean };

export type UpdateReservationDurationMutationVariables = Exact<{
  reservationId: Scalars['UUID'];
  to: Scalars['DateTime'];
}>;


export type UpdateReservationDurationMutation = { __typename?: 'Mutation', updateReservationDuration: boolean };

export type VerifyAccessPinMutationVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
  accessPin: Scalars['String'];
  projectId: Scalars['UUID'];
  serviceType: ServiceTypeEnum;
  tenantSubjectId: Scalars['UUID'];
}>;


export type VerifyAccessPinMutation = { __typename?: 'Mutation', verifyAccessPin: { __typename?: 'GqlVerifyAccessPinDto', bindingFee?: number | null } };

export type AppConfigQueryVariables = Exact<{
  pointId: Scalars['UUID'];
}>;


export type AppConfigQuery = { __typename?: 'Query', appConfig: { __typename?: 'AppConfigurationDto', isPointOnline: boolean, callCenterPhoneNumber: string, pointFullName: string, projectId: any, languages: Array<{ __typename?: 'LanguageDto', id: any, code: string, name: string, isDefaultLanguage: boolean }> } };

export type StorageUnitSizesQueryVariables = Exact<{ [key: string]: never; }>;


export type StorageUnitSizesQuery = { __typename?: 'Query', storageUnitSizes: Array<{ __typename?: 'StorageUnitSizeDto', isServiceModule: boolean, innerWidth: number, innerHeight: number, innerDepth: number }> };

export type StorageUnitQueryVariables = Exact<{
  pointId: Scalars['UUID'];
  storageUnitId: Scalars['UUID'];
}>;


export type StorageUnitQuery = { __typename?: 'Query', storageUnit: { __typename?: 'GqlStorageUnitDto', hasReservation: boolean, phoneNumber?: string | null, allocation?: { __typename?: 'AllocationDto', id: any, storageUnitId: any, status: AllocationStatus, content: { __typename?: 'ContentDto', id: any, storedAt?: string | null, trackingIdentifiers: Array<{ __typename?: 'TrackingIdentifierDto', identifier: string }> }, reservation: { __typename?: 'ReservationDtoV2', id: any, tenantSubjectId: any, isBlockingReservation: boolean, isDeliverySubmission: boolean, blockingReservationReasonDescription?: string | null, blockingReservationReason?: BlockingReservationReason | null, serviceType: ServiceType, languageCode?: Language | null, status: ReservationStatus, pickupDue?: string | null, createdAt: string, cancelledAt?: string | null, duration?: string | null, expiration: string, updatedAt: string, expiredAt?: string | null, terminatedAt?: string | null, finishedAt?: string | null, from: string, to: string, requiredStorageUnitFeatures: Array<StorageUnitFeature>, accessCredentials?: Array<{ __typename?: 'AccessCredentialsDto', id: any, phoneNumber?: string | null }> | null } } | null, storageUnit: { __typename?: 'StorageUnitDto', id: any, name?: string | null, storageUnitStatus: StorageUnitStatus, features: Array<StorageUnitFeature>, lock?: { __typename?: 'LockDto', lastStatus: LockStatus } | null, storageUnitSize?: { __typename?: 'StorageUnitSizeDto', isServiceModule: boolean, innerWidth: number, innerHeight: number, innerDepth: number } | null }, company?: { __typename?: 'CompanyInfoDto', name: string } | null } };

export type StorageUnitsQueryVariables = Exact<{
  pointId: Scalars['UUID'];
}>;


export type StorageUnitsQuery = { __typename?: 'Query', storageUnits: Array<{ __typename?: 'GqlStorageUnitListDto', isEmpty: boolean, allocationFrom?: string | null, allocationTo?: string | null, trackingIdentifier?: string | null, phoneNumber?: string | null, requiredStorageUnitFeatures: Array<StorageUnitFeature>, storageUnit: { __typename?: 'StorageUnitDto', id: any, name?: string | null, storageUnitStatus: StorageUnitStatus, features: Array<StorageUnitFeature>, lock?: { __typename?: 'LockDto', lastStatus: LockStatus } | null, storageUnitSize?: { __typename?: 'StorageUnitSizeDto', isServiceModule: boolean } | null } } | null> };

export type TenantsQueryVariables = Exact<{
  pointId: Scalars['UUID'];
}>;


export type TenantsQuery = { __typename?: 'Query', tenants: Array<{ __typename?: 'TenantDto', projectId: any, tenantSubjectId: any, tenantName?: string | null, tenantServiceType: ServiceTypeEnum } | null> };

export type StorageUnitStateChangedSubscriptionVariables = Exact<{
  pointId: Scalars['UUID'];
}>;


export type StorageUnitStateChangedSubscription = { __typename?: 'Subscription', storageUnitStateChanged?: { __typename?: 'StorageUnitSubscriptionDto', id: any, label: string, state: LockStatus } | null };

export const StorageUnitListFragmentDoc = `
    fragment StorageUnitList on GqlStorageUnitListDto {
  isEmpty
  allocationFrom
  allocationTo
  trackingIdentifier
  phoneNumber
  storageUnit {
    id
    name
    lock {
      lastStatus
    }
    storageUnitStatus
    storageUnitSize {
      isServiceModule
    }
    features
  }
  trackingIdentifier
  requiredStorageUnitFeatures
}
    `;
export const AllocationDtoFragmentDoc = `
    fragment AllocationDto on AllocationDto {
  id
  storageUnitId
  status
  content {
    id
    storedAt
    trackingIdentifiers {
      identifier
    }
  }
  reservation {
    id
    tenantSubjectId
    isBlockingReservation
    isDeliverySubmission
    blockingReservationReasonDescription
    blockingReservationReason
    serviceType
    languageCode
    status
    pickupDue
    createdAt
    cancelledAt
    duration
    expiration
    updatedAt
    expiredAt
    terminatedAt
    finishedAt
    from
    to
    accessCredentials {
      id
      phoneNumber
    }
    requiredStorageUnitFeatures
  }
}
    `;
export const StorageUnitSizeFragmentDoc = `
    fragment StorageUnitSize on StorageUnitSizeDto {
  isServiceModule
  innerWidth
  innerHeight
  innerDepth
}
    `;
export const StorageUnitDtoFragmentDoc = `
    fragment StorageUnitDto on GqlStorageUnitDto {
  hasReservation
  allocation {
    ...AllocationDto
  }
  storageUnit {
    id
    name
    lock {
      lastStatus
    }
    storageUnitStatus
    storageUnitSize {
      ...StorageUnitSize
    }
    features
  }
  company {
    name
  }
  phoneNumber
}
    `;
export const ChangeBlockingReservationTenantSubjectIdDocument = `
    mutation ChangeBlockingReservationTenantSubjectId($reservationId: UUID!, $tenantSubjectId: UUID!) {
  changeBlockingReservationTenantSubjectId(
    reservationId: $reservationId
    tenantSubjectId: $tenantSubjectId
  )
}
    `;
export const useChangeBlockingReservationTenantSubjectIdMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeBlockingReservationTenantSubjectIdMutation, TError, ChangeBlockingReservationTenantSubjectIdMutationVariables, TContext>) =>
    useMutation<ChangeBlockingReservationTenantSubjectIdMutation, TError, ChangeBlockingReservationTenantSubjectIdMutationVariables, TContext>(
      ['ChangeBlockingReservationTenantSubjectId'],
      (variables?: ChangeBlockingReservationTenantSubjectIdMutationVariables) => fetcher<ChangeBlockingReservationTenantSubjectIdMutation, ChangeBlockingReservationTenantSubjectIdMutationVariables>(ChangeBlockingReservationTenantSubjectIdDocument, variables)(),
      options
    );
export const ChangeStorageUnitStatusDocument = `
    mutation ChangeStorageUnitStatus($storageUnitId: UUID!, $status: StorageUnitStatus!, $statusChangeReason: String!) {
  changeStorageUnitStatus(
    storageUnitId: $storageUnitId
    status: $status
    statusChangeReason: $statusChangeReason
  ) {
    id
    storageUnitStatus
  }
}
    `;
export const useChangeStorageUnitStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeStorageUnitStatusMutation, TError, ChangeStorageUnitStatusMutationVariables, TContext>) =>
    useMutation<ChangeStorageUnitStatusMutation, TError, ChangeStorageUnitStatusMutationVariables, TContext>(
      ['ChangeStorageUnitStatus'],
      (variables?: ChangeStorageUnitStatusMutationVariables) => fetcher<ChangeStorageUnitStatusMutation, ChangeStorageUnitStatusMutationVariables>(ChangeStorageUnitStatusDocument, variables)(),
      options
    );
export const CreateBlockingReservationRequestV2Document = `
    mutation CreateBlockingReservationRequestV2($input: CreateBlockingReservationRequestInput!) {
  createBlockingReservationRequestV2(input: $input)
}
    `;
export const useCreateBlockingReservationRequestV2Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBlockingReservationRequestV2Mutation, TError, CreateBlockingReservationRequestV2MutationVariables, TContext>) =>
    useMutation<CreateBlockingReservationRequestV2Mutation, TError, CreateBlockingReservationRequestV2MutationVariables, TContext>(
      ['CreateBlockingReservationRequestV2'],
      (variables?: CreateBlockingReservationRequestV2MutationVariables) => fetcher<CreateBlockingReservationRequestV2Mutation, CreateBlockingReservationRequestV2MutationVariables>(CreateBlockingReservationRequestV2Document, variables)(),
      options
    );
export const CreateDeliveryReservationDocument = `
    mutation CreateDeliveryReservation($request: CreateDeliveryReservationRequestInput!) {
  createDeliveryReservation(request: $request) {
    pinCode
  }
}
    `;
export const useCreateDeliveryReservationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateDeliveryReservationMutation, TError, CreateDeliveryReservationMutationVariables, TContext>) =>
    useMutation<CreateDeliveryReservationMutation, TError, CreateDeliveryReservationMutationVariables, TContext>(
      ['CreateDeliveryReservation'],
      (variables?: CreateDeliveryReservationMutationVariables) => fetcher<CreateDeliveryReservationMutation, CreateDeliveryReservationMutationVariables>(CreateDeliveryReservationDocument, variables)(),
      options
    );
export const ForcedOpenStorageUnitWithReservationDocument = `
    mutation ForcedOpenStorageUnitWithReservation($pointId: UUID!, $storageUnitId: UUID!) {
  forcedOpenStorageUnitWithReservation(
    pointId: $pointId
    storageUnitId: $storageUnitId
  )
}
    `;
export const useForcedOpenStorageUnitWithReservationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ForcedOpenStorageUnitWithReservationMutation, TError, ForcedOpenStorageUnitWithReservationMutationVariables, TContext>) =>
    useMutation<ForcedOpenStorageUnitWithReservationMutation, TError, ForcedOpenStorageUnitWithReservationMutationVariables, TContext>(
      ['ForcedOpenStorageUnitWithReservation'],
      (variables?: ForcedOpenStorageUnitWithReservationMutationVariables) => fetcher<ForcedOpenStorageUnitWithReservationMutation, ForcedOpenStorageUnitWithReservationMutationVariables>(ForcedOpenStorageUnitWithReservationDocument, variables)(),
      options
    );
export const GenerateStorageUnitOneTimePasswordDocument = `
    mutation GenerateStorageUnitOneTimePassword($pointId: UUID!, $storageUnitId: UUID!) {
  generateStorageUnitOneTimePassword(
    pointId: $pointId
    storageUnitId: $storageUnitId
  ) {
    oneTimePassword
    validTo
  }
}
    `;
export const useGenerateStorageUnitOneTimePasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GenerateStorageUnitOneTimePasswordMutation, TError, GenerateStorageUnitOneTimePasswordMutationVariables, TContext>) =>
    useMutation<GenerateStorageUnitOneTimePasswordMutation, TError, GenerateStorageUnitOneTimePasswordMutationVariables, TContext>(
      ['GenerateStorageUnitOneTimePassword'],
      (variables?: GenerateStorageUnitOneTimePasswordMutationVariables) => fetcher<GenerateStorageUnitOneTimePasswordMutation, GenerateStorageUnitOneTimePasswordMutationVariables>(GenerateStorageUnitOneTimePasswordDocument, variables)(),
      options
    );
export const OpenEmptyStorageUnitDocument = `
    mutation OpenEmptyStorageUnit($pointId: UUID!, $storageUnitId: UUID!) {
  openEmptyStorageUnit(pointId: $pointId, storageUnitId: $storageUnitId)
}
    `;
export const useOpenEmptyStorageUnitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OpenEmptyStorageUnitMutation, TError, OpenEmptyStorageUnitMutationVariables, TContext>) =>
    useMutation<OpenEmptyStorageUnitMutation, TError, OpenEmptyStorageUnitMutationVariables, TContext>(
      ['OpenEmptyStorageUnit'],
      (variables?: OpenEmptyStorageUnitMutationVariables) => fetcher<OpenEmptyStorageUnitMutation, OpenEmptyStorageUnitMutationVariables>(OpenEmptyStorageUnitDocument, variables)(),
      options
    );
export const OpenEmptyStorageUnitsDocument = `
    mutation OpenEmptyStorageUnits($pointId: UUID!, $storageUnitIds: [UUID!]!) {
  openEmptyStorageUnits(pointId: $pointId, storageUnitIds: $storageUnitIds)
}
    `;
export const useOpenEmptyStorageUnitsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OpenEmptyStorageUnitsMutation, TError, OpenEmptyStorageUnitsMutationVariables, TContext>) =>
    useMutation<OpenEmptyStorageUnitsMutation, TError, OpenEmptyStorageUnitsMutationVariables, TContext>(
      ['OpenEmptyStorageUnits'],
      (variables?: OpenEmptyStorageUnitsMutationVariables) => fetcher<OpenEmptyStorageUnitsMutation, OpenEmptyStorageUnitsMutationVariables>(OpenEmptyStorageUnitsDocument, variables)(),
      options
    );
export const OpenServiceModuleDocument = `
    mutation OpenServiceModule($pointId: UUID!, $storageUnitId: UUID!) {
  openServiceModule(pointId: $pointId, storageUnitId: $storageUnitId)
}
    `;
export const useOpenServiceModuleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OpenServiceModuleMutation, TError, OpenServiceModuleMutationVariables, TContext>) =>
    useMutation<OpenServiceModuleMutation, TError, OpenServiceModuleMutationVariables, TContext>(
      ['OpenServiceModule'],
      (variables?: OpenServiceModuleMutationVariables) => fetcher<OpenServiceModuleMutation, OpenServiceModuleMutationVariables>(OpenServiceModuleDocument, variables)(),
      options
    );
export const OpenStorageUnitDocument = `
    mutation OpenStorageUnit($pointId: UUID!, $endUser: EndUserAccessPinInputDtoInput!, $isBlockingReservation: Boolean!, $storageUnitId: UUID, $projectId: UUID!, $serviceType: ServiceTypeEnum!, $tenantSubjectId: UUID, $singleTenantVerification: Boolean!) {
  openStorageUnit(
    pointId: $pointId
    endUser: $endUser
    storageUnitId: $storageUnitId
    isBlockingReservation: $isBlockingReservation
    projectId: $projectId
    serviceType: $serviceType
    tenantSubjectId: $tenantSubjectId
    singleTenantVerification: $singleTenantVerification
  ) {
    bindingFee
    storageUnitId
  }
}
    `;
export const useOpenStorageUnitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OpenStorageUnitMutation, TError, OpenStorageUnitMutationVariables, TContext>) =>
    useMutation<OpenStorageUnitMutation, TError, OpenStorageUnitMutationVariables, TContext>(
      ['OpenStorageUnit'],
      (variables?: OpenStorageUnitMutationVariables) => fetcher<OpenStorageUnitMutation, OpenStorageUnitMutationVariables>(OpenStorageUnitDocument, variables)(),
      options
    );
export const RequestMaintenanceStorageUnitDocument = `
    mutation RequestMaintenanceStorageUnit($pointId: UUID!, $storageUnitId: UUID!, $statusChangeReason: String!) {
  requestMaintenanceStorageUnit(
    pointId: $pointId
    storageUnitId: $storageUnitId
    statusChangeReason: $statusChangeReason
  )
}
    `;
export const useRequestMaintenanceStorageUnitMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestMaintenanceStorageUnitMutation, TError, RequestMaintenanceStorageUnitMutationVariables, TContext>) =>
    useMutation<RequestMaintenanceStorageUnitMutation, TError, RequestMaintenanceStorageUnitMutationVariables, TContext>(
      ['RequestMaintenanceStorageUnit'],
      (variables?: RequestMaintenanceStorageUnitMutationVariables) => fetcher<RequestMaintenanceStorageUnitMutation, RequestMaintenanceStorageUnitMutationVariables>(RequestMaintenanceStorageUnitDocument, variables)(),
      options
    );
export const ResendSmsDocument = `
    mutation ResendSms($pointId: UUID!, $reservationId: UUID!) {
  resendSms(pointId: $pointId, reservationId: $reservationId)
}
    `;
export const useResendSmsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResendSmsMutation, TError, ResendSmsMutationVariables, TContext>) =>
    useMutation<ResendSmsMutation, TError, ResendSmsMutationVariables, TContext>(
      ['ResendSms'],
      (variables?: ResendSmsMutationVariables) => fetcher<ResendSmsMutation, ResendSmsMutationVariables>(ResendSmsDocument, variables)(),
      options
    );
export const TerminateAllocationV2Document = `
    mutation TerminateAllocationV2($storageUnitId: UUID!) {
  terminateAllocationV2(storageUnitId: $storageUnitId)
}
    `;
export const useTerminateAllocationV2Mutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TerminateAllocationV2Mutation, TError, TerminateAllocationV2MutationVariables, TContext>) =>
    useMutation<TerminateAllocationV2Mutation, TError, TerminateAllocationV2MutationVariables, TContext>(
      ['TerminateAllocationV2'],
      (variables?: TerminateAllocationV2MutationVariables) => fetcher<TerminateAllocationV2Mutation, TerminateAllocationV2MutationVariables>(TerminateAllocationV2Document, variables)(),
      options
    );
export const UpdateContentStateDocument = `
    mutation UpdateContentState($pointId: UUID!, $tenantSubjectId: UUID!, $trackingIdentifier: String, $state: UpdateContentStateEnum!, $contentId: UUID, $storageUnitId: UUID, $userId: UUID, $isPickedUpBySender: Boolean) {
  updateContentState(
    pointId: $pointId
    tenantSubjectId: $tenantSubjectId
    trackingIdentifier: $trackingIdentifier
    state: $state
    contentId: $contentId
    storageUnitId: $storageUnitId
    userId: $userId
    isPickedUpBySender: $isPickedUpBySender
  )
}
    `;
export const useUpdateContentStateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateContentStateMutation, TError, UpdateContentStateMutationVariables, TContext>) =>
    useMutation<UpdateContentStateMutation, TError, UpdateContentStateMutationVariables, TContext>(
      ['UpdateContentState'],
      (variables?: UpdateContentStateMutationVariables) => fetcher<UpdateContentStateMutation, UpdateContentStateMutationVariables>(UpdateContentStateDocument, variables)(),
      options
    );
export const UpdateReservationDurationDocument = `
    mutation UpdateReservationDuration($reservationId: UUID!, $to: DateTime!) {
  updateReservationDuration(reservationId: $reservationId, to: $to)
}
    `;
export const useUpdateReservationDurationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateReservationDurationMutation, TError, UpdateReservationDurationMutationVariables, TContext>) =>
    useMutation<UpdateReservationDurationMutation, TError, UpdateReservationDurationMutationVariables, TContext>(
      ['UpdateReservationDuration'],
      (variables?: UpdateReservationDurationMutationVariables) => fetcher<UpdateReservationDurationMutation, UpdateReservationDurationMutationVariables>(UpdateReservationDurationDocument, variables)(),
      options
    );
export const VerifyAccessPinDocument = `
    mutation VerifyAccessPin($pointId: UUID!, $storageUnitId: UUID!, $accessPin: String!, $projectId: UUID!, $serviceType: ServiceTypeEnum!, $tenantSubjectId: UUID!) {
  verifyAccessPin(
    pointId: $pointId
    storageUnitId: $storageUnitId
    tenantSubjectId: $tenantSubjectId
    accessPin: $accessPin
    projectId: $projectId
    serviceType: $serviceType
  ) {
    bindingFee
  }
}
    `;
export const useVerifyAccessPinMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyAccessPinMutation, TError, VerifyAccessPinMutationVariables, TContext>) =>
    useMutation<VerifyAccessPinMutation, TError, VerifyAccessPinMutationVariables, TContext>(
      ['VerifyAccessPin'],
      (variables?: VerifyAccessPinMutationVariables) => fetcher<VerifyAccessPinMutation, VerifyAccessPinMutationVariables>(VerifyAccessPinDocument, variables)(),
      options
    );
export const AppConfigDocument = `
    query AppConfig($pointId: UUID!) {
  appConfig(pointId: $pointId) {
    languages {
      id
      code
      name
      isDefaultLanguage
    }
    isPointOnline
    callCenterPhoneNumber
    pointFullName
    projectId
  }
}
    `;
export const useAppConfigQuery = <
      TData = AppConfigQuery,
      TError = unknown
    >(
      variables: AppConfigQueryVariables,
      options?: UseQueryOptions<AppConfigQuery, TError, TData>
    ) =>
    useQuery<AppConfigQuery, TError, TData>(
      ['AppConfig', variables],
      fetcher<AppConfigQuery, AppConfigQueryVariables>(AppConfigDocument, variables),
      options
    );
export const StorageUnitSizesDocument = `
    query StorageUnitSizes {
  storageUnitSizes {
    ...StorageUnitSize
  }
}
    ${StorageUnitSizeFragmentDoc}`;
export const useStorageUnitSizesQuery = <
      TData = StorageUnitSizesQuery,
      TError = unknown
    >(
      variables?: StorageUnitSizesQueryVariables,
      options?: UseQueryOptions<StorageUnitSizesQuery, TError, TData>
    ) =>
    useQuery<StorageUnitSizesQuery, TError, TData>(
      variables === undefined ? ['StorageUnitSizes'] : ['StorageUnitSizes', variables],
      fetcher<StorageUnitSizesQuery, StorageUnitSizesQueryVariables>(StorageUnitSizesDocument, variables),
      options
    );
export const StorageUnitDocument = `
    query StorageUnit($pointId: UUID!, $storageUnitId: UUID!) {
  storageUnit(pointId: $pointId, storageUnitId: $storageUnitId) {
    ...StorageUnitDto
  }
}
    ${StorageUnitDtoFragmentDoc}
${AllocationDtoFragmentDoc}
${StorageUnitSizeFragmentDoc}`;
export const useStorageUnitQuery = <
      TData = StorageUnitQuery,
      TError = unknown
    >(
      variables: StorageUnitQueryVariables,
      options?: UseQueryOptions<StorageUnitQuery, TError, TData>
    ) =>
    useQuery<StorageUnitQuery, TError, TData>(
      ['StorageUnit', variables],
      fetcher<StorageUnitQuery, StorageUnitQueryVariables>(StorageUnitDocument, variables),
      options
    );
export const StorageUnitsDocument = `
    query StorageUnits($pointId: UUID!) {
  storageUnits(pointId: $pointId) {
    ...StorageUnitList
  }
}
    ${StorageUnitListFragmentDoc}`;
export const useStorageUnitsQuery = <
      TData = StorageUnitsQuery,
      TError = unknown
    >(
      variables: StorageUnitsQueryVariables,
      options?: UseQueryOptions<StorageUnitsQuery, TError, TData>
    ) =>
    useQuery<StorageUnitsQuery, TError, TData>(
      ['StorageUnits', variables],
      fetcher<StorageUnitsQuery, StorageUnitsQueryVariables>(StorageUnitsDocument, variables),
      options
    );
export const TenantsDocument = `
    query Tenants($pointId: UUID!) {
  tenants(pointId: $pointId) {
    projectId
    tenantSubjectId
    tenantName
    tenantServiceType
  }
}
    `;
export const useTenantsQuery = <
      TData = TenantsQuery,
      TError = unknown
    >(
      variables: TenantsQueryVariables,
      options?: UseQueryOptions<TenantsQuery, TError, TData>
    ) =>
    useQuery<TenantsQuery, TError, TData>(
      ['Tenants', variables],
      fetcher<TenantsQuery, TenantsQueryVariables>(TenantsDocument, variables),
      options
    );
export const StorageUnitStateChangedDocument = `
    subscription StorageUnitStateChanged($pointId: UUID!) {
  storageUnitStateChanged(pointId: $pointId) {
    id
    label
    state
  }
}
    `;