import {
  Alert,
  Box,
  Button,
  ErrorDetailInfo,
  Heading,
  Loading,
  Modal,
  showAlertModalError,
} from '@conteg/ui';
import { DeliveryReservationPin } from 'components/create-delivery-reservation/delivery-reservation-pin/delivery-reservation-pin';
import { CreateDeliveryReservationForm } from 'components/create-delivery-reservation/form';
import { CP_TENANT_ID, FORM_DATE_TIME_FORMAT } from 'config';
import { DateTime, Duration } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ServiceTypeEnum,
  StorageUnitDocument,
  useCreateDeliveryReservationMutation,
  useTenantsQuery,
} from 'types/generated/graphql';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type CreateDeliveryReservationProps = {
  projectId: string;
  pointId: string;
  maxWidth: number;
  maxHeight: number;
  maxDepth: number;
};

export const CreateDeliveryReservation = ({
  pointId,
  projectId,
  maxDepth,
  maxHeight,
  maxWidth,
}: CreateDeliveryReservationProps) => {
  const { t } = useTranslation();
  const invalidate = useInvalidateQueries();
  const [isOpen, setIsOpen] = useState(false);
  const [trackingIdentifier, setTrackingIdentifier] = useState<string>('');

  const {
    data,
    isLoading: isLoadingTenants,
    error: tenantsError,
  } = useTenantsQuery({ pointId });

  const {
    mutateAsync,
    isLoading: isCreatingReservation,
    data: pinCodeData,
    reset: resetCreateDeliveryReservationMutation,
  } = useCreateDeliveryReservationMutation({
    onSuccess: () => {
      invalidate([StorageUnitDocument]);
    },
    onError: (err) => {
      showAlertModalError(t('CreateDeliveryReservation.Form.CreateError'), err);
    },
  });

  const tenantOptions = data?.tenants
    .filter(
      (tenant) =>
        tenant?.tenantServiceType === ServiceTypeEnum.Delivery &&
        tenant.tenantSubjectId !== CP_TENANT_ID
    )
    .map((tenant) => ({
      label: tenant?.tenantName || '',
      value: tenant?.tenantSubjectId,
    }));

  const handleClose = () => {
    setIsOpen(false);
    resetCreateDeliveryReservationMutation();
  };

  return (
    <>
      <Button
        size="xs"
        onClick={() => setIsOpen(true)}
        title={t(
          'CreateDeliveryReservation.Form.CreateDeliveryReservationButton'
        )}
      />
      <Modal isOpen={isOpen} onClose={handleClose} showCloseButton size="xl">
        {pinCodeData?.createDeliveryReservation?.pinCode ? (
          <DeliveryReservationPin
            trackingIdentifier={trackingIdentifier}
            pinCode={pinCodeData.createDeliveryReservation.pinCode}
          />
        ) : (
          <>
            <Box marginBottom="4rem">
              <Heading
                variant="h1"
                title={t('CreateDeliveryReservation.Form.Title')}
              />
            </Box>
            {isLoadingTenants && <Loading />}
            {tenantsError && (
              <ErrorDetailInfo
                error={tenantsError}
                title={t('CreateDeliveryReservation.Form.TenantsError')}
              />
            )}
            {!isLoadingTenants && !tenantsError && !tenantOptions?.length && (
              <Alert
                type="error"
                message={t('CreateDeliveryReservation.Form.NoTenantsError')}
              />
            )}
            {tenantOptions && tenantOptions.length > 0 && (
              <CreateDeliveryReservationForm
                isLoading={isCreatingReservation}
                tenantsOptions={tenantOptions}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                maxDepth={maxDepth}
                onSubmit={({
                  depth,
                  height,
                  storeDuration,
                  width,
                  phoneNumber,
                  trackingIdentifier,
                  storedFromDate,
                  storedFromTime,
                  stockInToDate,
                  stockInToTime,
                  tenantSubjectId,
                }) => {
                  const storedFromIso = DateTime.fromFormat(
                    `${storedFromDate} ${storedFromTime}`,
                    FORM_DATE_TIME_FORMAT
                  ).toISO();

                  const expirationDuration = DateTime.fromFormat(
                    `${stockInToDate} ${stockInToTime}`,
                    FORM_DATE_TIME_FORMAT
                  ).toISO();

                  const duration = Duration.fromObject({
                    hours: storeDuration,
                  }).toISO();

                  setTrackingIdentifier(trackingIdentifier);

                  mutateAsync({
                    request: {
                      duration,
                      tenantSubjectId,
                      contentDepth: depth,
                      phoneNumber,
                      contentHeight: height,
                      contentWidth: width,
                      expiration: expirationDuration,
                      trackingIdentifier,
                      pointId,
                      projectId,
                      from: storedFromIso,
                    },
                  });
                }}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};
