import { Button, ErrorDetailInfo, Flex, styled } from '@conteg/ui';
import LayoutKioskLogout from 'components/layout-kiosk-logout/layout-kiosk-logout';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfigQuery } from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useIsAuthenticated } from 'utils/device-auth/device-auth-store';
import { useIdleModalStore } from 'utils/kiosk-idle-modal/store';
import { addStylesToHtml } from 'utils/layout/add-styles-to-html';
import { dispatchLockLauncherWeb } from 'utils/post-messages/dispatch-leave-page';

const TOP_BAR_HEIGHT = '6rem';
const BOTTOM_BAR_HEIGHT = '12rem';
const FLEX_LAYOUT_GAP = '2rem';

const FlexLayout = styled.div<{ gap: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  padding: 2rem;

  div:last-child {
    margin-top: auto;
  }
`;

const KioskContent = styled.div<{ heightSub: string }>`
  width: 100%;
  margin-top: 3rem;
  max-height: calc(100% - ${({ heightSub }) => heightSub});
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem;

  input,
  #select-container,
  #options-container {
    background-color: #030218;
  }

  input {
    color: white;
  }

  table {
    color: ${({ theme }) => theme.colors.white};

    tr {
      color: ${({ theme }) => theme.colors.white};
    }

    th,
    td {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  button {
    color: ${({ theme }) => theme.colors.white};
  }
`;
const KioskText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.typography.text.large.fontFamily};
  font-weight: ${({ theme }) => theme.typography.text.large.fontWeight};
  font-size: ${({ theme }) => theme.typography.text.large.fontSize};
  letter-spacing: ${({ theme }) => theme.typography.text.large.letterSpacing};
  line-height: ${({ theme }) => theme.typography.text.large.lineHeight};
`;

const LayoutKiosk = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const setIsModalOpen = useIdleModalStore.getState().setIsModalOpen;
  const isAuthenticated = useIsAuthenticated();

  const { data, error: appConfigError } = useAppConfigQuery(
    {
      pointId,
    },
    { enabled: isAuthenticated }
  );

  useEffect(() => {
    addStylesToHtml();
  }, []);

  if (appConfigError) {
    return (
      <FlexLayout gap={FLEX_LAYOUT_GAP}>
        <KioskContent
          heightSub={`(${TOP_BAR_HEIGHT} + ${BOTTOM_BAR_HEIGHT} + 2 * ${FLEX_LAYOUT_GAP})`}
        >
          <ErrorDetailInfo
            title={t('Error.AppConfigLoadError')}
            error={appConfigError}
          />
        </KioskContent>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout gap={FLEX_LAYOUT_GAP}>
      <KioskContent
        heightSub={`(${TOP_BAR_HEIGHT} + ${BOTTOM_BAR_HEIGHT} + 2 * ${FLEX_LAYOUT_GAP})`}
      >
        {children}
      </KioskContent>
      <Flex
        alignItems="flex-end"
        justifyContent="space-between"
        gap="4rem"
        height={BOTTOM_BAR_HEIGHT}
      >
        <Flex gap="3rem">
          <LayoutKioskLogout />
          {isAuthenticated && (
            <>
              <Button
                title={t('Kiosk.Layout.Button.LockPoint')}
                onClick={dispatchLockLauncherWeb}
              />
              <Button
                title={t('Kiosk.Layout.Button.ActiveLock')}
                onClick={() => setIsModalOpen(true)}
              />
            </>
          )}
        </Flex>
        {isAuthenticated && (
          <KioskText data-test="layout-support">
            {`${t('Kiosk.Layout.SupportPhoneNumber')}: ${
              data?.appConfig?.callCenterPhoneNumber ?? ''
            }`}
          </KioskText>
        )}
      </Flex>
    </FlexLayout>
  );
};

export default LayoutKiosk;
