import {
  Box,
  Button,
  Flex,
  Input,
  showAlertModalError,
  Text,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StorageUnitDocument,
  StorageUnitStatus,
  useChangeStorageUnitStatusMutation,
} from 'types/generated/graphql';
import { useOnStatusChangeHandler } from 'utils/hooks/storage-units-event-handlers';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type FormInputs = {
  reason: string;
};

type UpdateUnitStatusProps = {
  storageUnitId: string;
  status: StorageUnitStatus;
};

const UpdateUnitStatus = ({ storageUnitId, status }: UpdateUnitStatusProps) => {
  const { t } = useTranslation();
  const onStatusChange = useOnStatusChangeHandler();
  const invalidate = useInvalidateQueries();
  const { mutateAsync, isLoading } = useChangeStorageUnitStatusMutation();

  const [formNotice, setFormNotice] = useState<string>('');

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      reason: '',
    },
  });

  const handleUpdateStatus: SubmitHandler<FormInputs> = async (data) => {
    setFormNotice('');
    await mutateAsync(
      {
        storageUnitId,
        status,
        statusChangeReason: data.reason,
      },
      {
        onSuccess: () => {
          setFormNotice('Page.StorageUnit.Actions.Status.Success');
          invalidate([StorageUnitDocument]);
          if (onStatusChange) onStatusChange(storageUnitId, status);
          reset();
        },
        onError: (error) => {
          showAlertModalError(
            t('Page.StorageUnit.Actions.Status.Error'),
            error
          );
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(handleUpdateStatus)}>
      <Flex flexDirection="column" gap="6rem" mt="5rem">
        {formNotice && (
          <Notification testId="update-unit-status" message={formNotice} />
        )}
        {status === StorageUnitStatus.Broken ? (
          <Input
            testId="update-unit-status-reason"
            label={t('Page.StorageUnit.Actions.Status.Reason')}
            placeholder={t('Page.StorageUnit.Actions.Status.Reason')}
            error={errors.reason?.message && t(errors.reason?.message)}
            {...register('reason', { required: 'Error.Form.Required' })}
          />
        ) : (
          <Text
            variant="highlight"
            content={t('Page.StorageUnit.Actions.Status.Unblock.Confirm')}
          />
        )}
        <Box mx="auto">
          <Button
            testId="open-without-pin-button"
            variant="primary"
            size="xl"
            title={t(
              `Page.StorageUnit.Actions.Status.${
                status === StorageUnitStatus.Broken ? 'Block' : 'Unblock'
              }`
            )}
            disabled={isLoading}
          />
        </Box>
      </Flex>
    </form>
  );
};

export default UpdateUnitStatus;
