import {
  Button,
  Flex,
  Heading,
  Input,
  InputTypes,
  showAlertModalError,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StorageUnitDocument,
  useUpdateReservationDurationMutation,
} from 'types/generated/graphql';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type FormInputs = {
  date: string;
  time: string;
};

const getMaxTime = ({
  selectedDate,
  maxDate,
  maxTime,
}: {
  selectedDate: string;
  maxDate: string;
  maxTime: string;
}) => {
  if (selectedDate === maxDate) {
    return maxTime;
  }
  return;
};

const ChangeReservationDuration = ({
  reservationId,
  from,
  to,
}: {
  reservationId: string;
  from: string;
  to: string;
}) => {
  const { t } = useTranslation();
  const invalidate = useInvalidateQueries();
  const [formNotice, setFormNotice] = useState<string>('');

  const formActions = useForm<FormInputs>({
    defaultValues: {
      date: DateTime.now().toISODate(),
      time: DateTime.now().toFormat('HH:mm'),
    },
  });
  const { handleSubmit, reset, register, watch } = formActions;

  const date = watch('date');
  const time = watch('time');
  const lastPossibleReservationTime = DateTime.fromISO(to).toFormat('HH:mm');

  const { mutate: updateReservationDuration, isLoading } =
    useUpdateReservationDurationMutation();

  const handleUpdateReservationDuration = () => {
    setFormNotice('');
    updateReservationDuration(
      {
        reservationId,
        to: DateTime.fromISO(`${date}T${time}`).toUTC().toString(),
      },
      {
        onSuccess: () => {
          setFormNotice(
            'Page.StorageUnit.Actions.UpdateReservationDuration.Success'
          );
          invalidate([StorageUnitDocument]);
          reset();
        },
        onError: (error) => {
          showAlertModalError(
            t('Page.StorageUnit.Actions.UpdateReservationDuration.Error'),
            error
          );
        },
      }
    );
  };

  return (
    <FormProvider {...formActions}>
      <form onSubmit={handleSubmit(handleUpdateReservationDuration)}>
        <Flex flexDirection="column" alignItems="center" gap="5rem">
          <Heading
            title={t(
              'Page.StorageUnit.Actions.UpdateReservationDuration.Heading'
            )}
            variant="h2"
          />
          {formNotice && (
            <Notification
              testId="update-reservation-duration-notification"
              message={formNotice}
            />
          )}
          <Flex gap="4rem">
            <Input
              testId="input-date"
              placeholder={t('Date')}
              label={t('Date')}
              type={InputTypes.DATE}
              min={DateTime.fromISO(from).toISODate()}
              max={DateTime.fromISO(to).toISODate()}
              {...register('date', { required: true })}
            />
            <Input
              testId="input-time"
              placeholder={t('Time')}
              label={t('Time')}
              type={InputTypes.TIME}
              max={getMaxTime({
                selectedDate: date,
                maxDate: DateTime.fromISO(to).toISODate(),
                maxTime: lastPossibleReservationTime,
              })}
              {...register('time', { required: true })}
            />
          </Flex>
          <Flex alignItems="center">
            <Button
              testId="update-reservation-duration-confirmation-button"
              variant="primary"
              size="xl"
              title={t('Page.Reservation.Form.Button')}
              disabled={isLoading}
              type="submit"
            />
          </Flex>
        </Flex>
      </form>
    </FormProvider>
  );
};

export default ChangeReservationDuration;
